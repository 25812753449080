<template>
  <div>
    <shop-head currentPageName="AGREEMENT"></shop-head>
    <div class="container">
      <img src="../../assets/images/qa.jpg" alt="" class="big-img">
      <div v-for="(item,index) in list" :key="index" class="list-box">
        <div  class="question-item">Q: {{ item.question }}</div>
        <div  class="answer-item">A: {{ item.answer }}</div>
      </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import shopHead from '../../components/shop/head'
import commonFooter from '../../components/common/footer'
export default {
  components: {
    shopHead,
    commonFooter
  },
  data() {
    return {
      list: [
        {
          question: "May I have samples?",
          answer: "Yes, of course. Sample cost can be refunded if you place formal orders in the future. You just need to tell me consignee’s contact information and courier account Number. If you don’t have a courier account, we 'll calculate for you, freight prepaid."
        },
        {
          question: "Can I use our own designed package?",
          answer: "Yes, size, color, logo and packaging style of product are customized"
        },
        {
          question: "What’s your MOQ?",
          answer: "Normally, 300sets/item. We also warm welcome any trial order whose QTY less is than our MOQ. If you have a trial order also welcome to tell me."
        },
        {
          question: "When’s your delivery time?",
          answer: "Generally, 1-3 working days for existing samples, within 30 days for mass production."
        },
        {
          question: "How can I pay? ",
          answer: " I strong recommand Trade Assurance service on the Alibaba platform. T/T, L/C, Western Union, MoneyGram etc are acceptable."
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.big-img {
  width: 80%;
  height: auto;
  margin: 20px auto;
  display: block;
}
.list-box {
  text-align: justify;
  margin-bottom: 20px;
  .question-item {
    width: 100%;
    line-height: 20px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .answer-item {
    width: 100%;
    line-height: 20px;
    font-size: 14px;
  }
}
</style>